import sukiyaki from './images/image-list/sukiyaki.jpg';
import french_toast from './images/image-list/french-toast.jpg';
import yakisoba from './images/image-list/yakisoba.jpg';
import yakitori from './images/image-list/yakitori.jpg';
import birthday_cake from './images/image-list/birthday-cake.jpg';
import napolitan_pasta from './images/image-list/napolitan-pasta.jpg';
import omurice from './images/image-list/omurice.jpg';
import katsu_curry from './images/image-list/katsu-curry.jpg';
import bento from './images/image-list/bento.jpg';
import hamburger from './images/image-list/hamburger.jpg';
import oyakodon from './images/image-list/oyakodon.jpg';
import steak from './images/image-list/steak.jpg';
import tomato_salad from './images/image-list/tomato-salad.jpg';
import corn_dog_tteokbokki from './images/image-list/corn-dog-tteokbokki.jpg';
import butter_cookie from './images/image-list/butter-cookie.jpg';
import waffle from './images/image-list/waffle.jpg';
import takoyaki from './images/image-list/takoyaki.jpg';
import spam_musubi from './images/image-list/spam-musubi.jpg';
import inari_sushi from './images/image-list/inari-sushi.jpg';
import curry_udon from './images/image-list/curry-udon.jpg';
import butadon from './images/image-list/butadon.jpg';
import eggs_in_hell from './images/image-list/eggs-in-hell.jpg';
import dak_galbi from './images/image-list/dak-galbi.jpg';
import korean_chicken from './images/image-list/korean-chicken.jpg';
import taiyaki from './images/image-list/taiyaki.jpg';
import cream_stew from './images/image-list/cream-stew.jpg';
import pizza from './images/image-list/pizza.jpg';
import hotteok from './images/image-list/hotteok.jpg';
import mapo_tofu from './images/image-list/mapo-tofu.jpg';
import strawberry_burrata_salad from './images/image-list/strawberry-burrata-salad.jpg';
import fried_tofu from './images/image-list/fried-tofu.jpg';

const itemData = [
  {
    img: fried_tofu,
    title: '揚げ出し豆腐 (Fried tofu with soup stock)',
    titleEN: 'Fried tofu with soup stock',
    titleJP: '揚げ出し豆腐',
    price: '????',
    videoId: 'fyLFp8zyw8o',
    modalCols: 4,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: strawberry_burrata_salad,
    title: 'いちごブッラータサラダ (Strawberry burrata salad)',
    titleEN: 'Strawberry burrata salad',
    titleJP: 'いちごブッラータサラダ',
    price: '????',
    videoId: 'bliszwORCR0',
    modalCols: 4,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: mapo_tofu,
    title: 'マーボードウフ (Mapo tofu)',
    titleEN: 'Mapo tofu',
    titleJP: 'マーボードウフ',
    price: '????',
    videoId: 'GhNAWcnMGaw',
    modalCols: 5,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: hotteok,
    title: 'ホットク (Hotteok)',
    titleEN: 'Hotteok',
    titleJP: 'ホットク',
    price: '????',
    videoId: 'ljDQKVJZIaA',
    modalCols: 4,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: pizza,
    title: 'ピザ (Pizza)',
    titleEN: 'Pizza',
    titleJP: 'ピザ',
    price: '????',
    videoId: '_n-83i6OCBE',
    modalCols: 4,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: cream_stew,
    title: 'クリームシチュー (Cream stew)',
    titleEN: 'Cream stew',
    titleJP: 'クリームシチュー',
    price: '????',
    videoId: 'WmwgmSHvenA',
    modalCols: 4,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: taiyaki,
    title: 'たい焼き (Taiyaki)',
    titleEN: 'Taiyaki',
    titleJP: 'たい焼き',
    price: '????',
    videoId: '56J8FkZqc3o',
    modalCols: 4,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: korean_chicken,
    title: 'フライドチキン (Korean chicken)',
    titleEN: 'Korean chicken',
    titleJP: 'フライドチキン',
    price: '????',
    videoId: 'CXiR7-5O8NQ',
    modalCols: 4,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: dak_galbi,
    title: 'タッカルビ (Dak-galbi)',
    titleEN: 'Dak-galbi',
    titleJP: 'タッカルビ',
    price: '????',
    videoId: 'LNIT8gx-mug',
    modalCols: 4,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: eggs_in_hell,
    title: 'シャクシューカ (Eggs in hell)',
    titleEN: 'Eggs in hell (Shakshuka)',
    titleJP: 'シャクシューカ',
    price: '????',
    videoId: 'eAYYBo31Yag',
    modalCols: 5,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: butadon,
    title: '豚丼 (Butadon)',
    titleEN: 'Butadon',
    titleJP: '豚丼',
    price: '????',
    videoId: 'u3hzNviTyBM',
    modalCols: 4,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: curry_udon,
    title: 'カレーうどん (Curry udon)',
    titleEN: 'Curry udon',
    titleJP: 'カレーうどん',
    price: '????',
    videoId: 'Jgx5AnMWgL4',
    modalCols: 4,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: inari_sushi,
    title: 'いなり寿司 (Inari sushi)',
    titleEN: 'Inari sushi',
    titleJP: 'いなり寿司',
    price: '????',
    videoId: 'gVLwPI5qVhQ',
    modalCols: 4,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: corn_dog_tteokbokki,
    title: 'コーンドッグとトッポッキ (Corn dog & tteokbokki)',
    titleEN: 'Corn dog & tteokbokki',
    titleJP: 'コーンドッグとトッポッキ',
    price: '????',
    videoId: '4tIF56lZMqo',
    modalCols: 4,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: tomato_salad,
    title: 'トマトサラダ (Tomato salad)',
    titleEN: 'Tomato salad',
    titleJP: 'トマトサラダ',
    price: '????',
    videoId: 'K0-apUyUfbQ',
    modalCols: 5,
    recipeCard: true,
    ingredientsEN: [
      { name: '2 Tomatoes' },
      { name: '24 Cherry Tomatoes' },
      { name: 'Edible Grass' },
      { name: 'Cheese' },
      { name: 'Extra Virgin Olive Oil' },
      { name: 'Salt' },
      { name: 'Pepper' },
      { name: 'Stevia' },
      { name: 'Cream Cheese' },
      { name: 'Crackers' },
    ],
    ingredientsJP: [],
    stepsEN: [
      { step: 'Slice the Cherry Tomatoes and serve them on a plate', time: 1389 },
      { step: 'Slice the Tomatoes and put them in a Blender', time: 1840 },
      { step: 'Place the Grass and the Cheese on top of the Cherry Tomatoes', time: 2068 },
      { step: 'Use the Extra Virgin Olive Oil, Salt and Pepper to season the salad', time: 2601 },
      { step: 'Add Stevia and water to the Blender and blend', time: 2698 },
      { step: 'Serve the Tomato Juice in a cup', time: 2925 },
      { step: 'Eat with Crackers and Cream Cheese', time: 3264 },
    ],
    stepsJP: [],
  },
  {
    img: steak,
    title: 'ステーキ (Steak)',
    titleEN: 'Steak',
    titleJP: 'ステーキ',
    price: '????',
    videoId: 'IiuOwq97BRo',
    modalCols: 4,
    recipeCard: true,
    ingredientsEN: [
      { name: 'Cucumber' },
      { name: 'Salt & pepper' },
      { name: 'Steak' },
      { name: 'Garlic clove' },
      { name: 'Thyme' },
      { name: 'Mustard' },
      { name: 'Wasabi paste' },
      { name: 'Pineapple' },
      { name: 'Sesame dressing' },
      { name: 'Oil' },
      { name: 'Butter' },
      { name: 'Egg soft boiled' },
      { name: 'Bibim men' }
    ],
    ingredientsJP: [],
    stepsEN: [
      { step: 'Slice Cucumber into thin strips', time: 1160 },
      { step: 'Season Steak with salt and pepper', time: 1868 },
      { step: 'Add oil to the pan and heat until very hot', time: 2345 },
      { step: 'Add Steak to the pan and sear each side for 30 seconds.', time: 2398 },
      { step: 'Continue to cook, turning the Steak', time: 2489 },
      { step: 'Add crushed Garlic cloves, Thyme and more oil', time: 2568 },
      { step: 'Remove Thyme and add Butter, baste the Steak in the melted Butter / Garlic.', time: 2755 },
      { step: 'Rest the Steak on a wire rack and cover with tin foil.', time: 2856 },
      { step: 'Boil a pot with water, cook Bibim men noodles for 3 minutes.', time: 2958 },
      { step: 'Wash Noodles in cold water.', time: 3306 },
      { step: 'Grill Pineapple.', time: 3410 },
      { step: 'Add sauce to Bibim men ramen and stir.', time: 3467 },
      { step: 'Add Cucumber and Boiled egg to the ramen.', time: 3599 },
      { step: 'Serve Steak with Truffle salt, Wasabi and Mustard.', time: 3722 },
      { step: 'Add some Sesame Dressing to some sliced Cucumber.', time: 3872 },
      { step: 'Slice up Steak into strips.', time: 3946 },
    ],
    stepsJP: [],
  },
  {
    img: sukiyaki,
    title: 'すき焼き (Sukiyaki)',
    titleEN: 'Sukiyaki',
    titleJP: 'すき焼き',
    price: '5,000円',
    videoId: '1Ed8GLLJJHk',
    modalCols: 4,
    recipeCard: true,
    ingredientsEN: [
      { name: 'Sukiyaki sauce' },
      { name: 'Beef thinly sliced' },
      { name: 'Shitaki Mushrooms' },
      { name: 'Enoki Mushrooms' },
      { name: 'Crown Daisy' },
      { name: 'Chinese cabbage' },
      { name: 'Baked tofu' },
      { name: 'Brown sugar' },
      { name: 'Shirataki/Konjac Noodles' },
      { name: '1/2 White onion' },
      { name: '2 Spring onion' },
      { name: 'Eggs' },
      { name: 'Oil' },
    ],
    ingredientsJP: [],
    stepsEN: [
      { step: 'Remove stalks and slice a star shape onto the top of the Shitaki Mushrooms', time: 2087 },
      { step: 'Roughly chop the Crown daisy', time: 2476 },
      { step: 'Slice the Chinese cabbage', time: 2632 },
      { step: 'Cut the half Onion into half again', time: 2777 },
      { step: 'Cut the Tofu in to small chunks', time: 2894 },
      { step: 'Heat the pan and add a small amount of oil', time: 3113 },
      { step: 'Cut the Spring onions into chunks', time: 3369 },
      { step: 'Fry the Spring onions', time: 3521 },
      { step: 'Add the Chinese cabbage and onion to the pan', time: 3700 },
      { step: 'Add the Crown daisy to the pan', time: 3748 },
      { step: 'Add the Shitaki Mushrooms to the pan', time: 3768 },
      { step: 'Add the Enoki Mushrooms to the pan', time: 3846 },
      { step: 'Add the Tofu to the pan', time: 3940 },
      { step: 'Add the Konjac noodles to the pan', time: 3975 },
      { step: 'Start to fry the Beef slices', time: 4037 },
      { step: 'Add the Sugar', time: 4110 },
      { step: 'Add the Sukiyaki sauce', time: 4137 },
      { step: 'Turn the Beef to cook evenly on each side', time: 4216 },
      { step: 'Crack the Eggs into a bowl and stir', time: 4294 },
    ],
    stepsJP: [],
  },
  {
    img: french_toast,
    title: 'フレンチトースト (French toast)',
    titleEN: 'French toast',
    titleJP: 'フレンチトースト',
    price: 'Good question',
    videoId: 'UZdP0fDG8cg',
    modalCols: 4,
    recipeCard: true,
    ingredientsEN: [
      { name: '2 Eggs' },
      { name: 'Salt' },
      { name: 'Butter' },
      { name: 'Mint leaves' },
      { name: 'Bread' },
      { name: 'Banana' },
      { name: 'Blueberry' },
      { name: 'Mango' },
      { name: 'Grapefruit' },
      { name: 'Apricot Jam' },
      { name: 'Aligo sweetener' },
      { name: 'Whipped Cream' },
      { name: 'Milk' },
    ],
    ingredientsJP: [],
    stepsEN: [
      { step: 'Set a slice of bread in a bowl and leave on the side', time: 1160 },
      { step: 'Crack 2 eggs in a bowl', time: 2004 },
      { step: 'Whip them until both are combined', time: 2044 },
      { step: 'Add the sweetener and the milk', time: 2217 },
      { step: 'Continue whipping a few seconds to combine the sweetener and milk with the egg mixture', time: 1160 },
      { step: 'Drop the egg mix into the bread bowl', time: 2501 },
      { step: 'Coat the bread on both sides and leave aside for 10 minutes', time: 2501 },
      { step: 'Cut the banana in small bite size peices', time: 3046 },
      { step: 'Remove the skin from the Mango and cut it in small bite size pieces', time: 3939 },
      { step: 'Remove the skin on the Grapefruit and cut it in small bite size pieces', time: 4213 },
      { step: 'In a pan melt the butter and fry the bread', time: 5617 },
      { step: 'Cook until both sides are golden brown then take off the heat and put the bread on a plate', time: 10424 },
      { step: 'Add the Bananer, Mango, Grapefruit and Blueberry on the plate', time: 1160 },
      { step: 'Add mint leaves on top of the fruits', time: 10715 },
      { step: 'Add whipped cream and apricot jam on the side and serve', time: 10915 },

    ],
    stepsJP: [],
  },
  {
    img: yakisoba,
    title: '焼きそば (Yakisoba)',
    titleEN: 'Yakisoba',
    titleJP: '焼きそば',
    price: '2,000円',
    videoId: 'mbkE_SqVR58',
    modalCols: 5,
    recipeCard: true,
    ingredientsEN: [
      { name: '1/4 Cabbage' },
      { name: '100g Pork' },
      { name: '50g Chives' },
      { name: 'Chinese Noodles' },
      { name: 'Salt' },
      { name: 'Pepper' },
      { name: 'Yakisoba Sauce' },
      { name: 'Oyster Sauce' },
      { name: 'Tsuyu Sauce' },
      { name: '1 Egg' },
      { name: 'Pickled Ginger' },
      { name: 'Bonito Flakes' },
      { name: 'Aonori' },
    ],
    ingredientsJP: [],
    stepsEN: [
      { step: 'Slice the Cabbage', time: 1417 },
      { step: 'Slice the Chives', time: 1621 },
      { step: 'Slice the Pork', time: 1782 },
      { step: 'Warm the pan', time: 1904 },
      { step: 'Grill the noodles for 10 minutes', time: 2023 },
      { step: 'Season the Pork with Salt and Pepper', time: 2796 },
      { step: 'Add Oil to the pan and stir-fry the Pork for 3 minutes', time: 2870 },
      { step: 'Add the Cabbage to the pan and continue stirring for 4 minutes', time: 3070 },
      { step: 'Add the Noodles to the pan and continue stirring for 2 minutes', time: 3315 },
      { step: 'Add one spoon of Yakisoba Sauce, one spoon of Oyster Sauce and one spoon of Tsuyu Sauce to the pan and continue stirring for 5 minutes', time: 3474 },
      { step: 'Serve on a plate', time: 3812 },
      { step: 'Add the Chives to the pan and stir-fry for 2 minutes', time: 3891 },
      { step: 'Add the Chives on top of the plate', time: 4042 },
      { step: 'Add Oil to the pan and cook the Egg sunny side up', time: 4125 },
      { step: 'Add the Pickled Ginger to the side of the plate', time: 4200 },
      { step: 'Add the Bonito Flakes on top of the plate', time: 4222 },
      { step: 'Add the Aonori on top of the plate', time: 4245 },
      { step: 'Add the Egg to the side of the plate', time: 4321 },
    ],
    stepsJP: [],
  },
  {
    img: oyakodon,
    title: '親子丼 (Oyakodon)',
    titleEN: 'Oyakodon',
    titleJP: '親子丼',
    price: '????',
    videoId: 'dMYMXT6uGrY',
    modalCols: 4,
    recipeCard: true,
    ingredientsEN: [
      { name: '1 Large Onion' },
      { name: 'Chicken breast or thigh meat' },
      { name: '2 Large Eggs' },
      { name: '1 Spring Onion' },
      { name: 'Soy Sauce' },
      { name: 'Cooking oil' },
      { name: 'Rice' },
    ],
    ingredientsJP: [],
    stepsEN: [
      { step: 'Slice 1/2 a Large onion', time: 652 },
      { step: 'Warm the pan', time: 771 },
      { step: 'Slice a Spring Onion', time: 804 },
      { step: 'Fry Chicken in the pan turning until cooked', time: 864 },
      { step: 'Slice the Chicken into smaller chunks', time: 1128 },
      { step: 'To make the sauce combine: 1 spoon of Oil, 1 spoon Soy Sauce and 1 spoon Water', time: 1219 },
      { step: 'Add the sliced Large Onion to the pan and cook', time: 1322 },
      { step: 'Add one more spoon of Soy Sauce', time: 1445 },
      { step: 'Add the cooked Chicken back to the pot', time: 1486 },
      { step: 'Add one more spoon of Water if its starting to dry', time: 1520 },
      { step: 'Crack 1 Egg in beaker, swirl 3 times to mix and then add to pot.', time: 1716 },
      { step: 'Cook for 1 minute on low heat', time: 1779 },
      { step: 'Repeat step 11 with another egg. Then add the Spring Onions.', time: 1944 },
      { step: 'Cook a portion of Rice, add to pot and mix', time: 2074 },
    ],
    stepsJP: []
  },
  {
    img: yakitori,
    title: '焼き鳥 (Yakitori)',
    titleEN: 'Yakitori',
    titleJP: '焼き鳥',
    price: '500円',
    videoId: 'OdYSQ_I7gYQ',
    modalCols: 5,
    recipeCard: true,
    ingredientsEN: [
      { name: '6 Slices of Bacon' },
      { name: '3 Cocktail Sausages' },
      { name: '1/4 Corn' },
      { name: '1 Pineapple Slice' },
      { name: 'Cheese' },
      { name: '1 Mushroom' },
      { name: '2 Asparagus' },
      { name: '2 Leeks' },
      { name: '3 Cherry Tomatoes' },
      { name: 'Beef' },
      { name: 'Pork' },
      { name: 'Chicken' },
      { name: '2 Shrimps' },
    ],
    ingredientsJP: [],
    stepsEN: [
      { step: 'Slice the Leeks', time: 1221 },
      { step: 'Slice the Pork', time: 1384 },
      { step: 'Slice the Beef', time: 1510 },
      { step: 'In a skewer, add 3 slices of Pork and 2 slices of Leek', time: 1539 },
      { step: 'In another skewer, add 3 slices of Beef and 2 slices of Leek', time: 1788 },
      { step: 'Slice the Chicken', time: 1931 },
      { step: 'In another skewer, add 3 slices of Chicken and 2 slices of Leek', time: 1996 },
      { step: 'Slice the Asparagus', time: 2192 },
      { step: 'Place 2 to 3 slices of Asparagus inside Bacon slices, make 3 rolls and put them in a skewer', time: 2263 },
      { step: 'In another skewer, add the Mushroom', time: 2566 },
      { step: 'Place each Cherry Tomato inside Bacon slices, make 3 rolls and put them in a skewer', time: 2747 },
      { step: 'In another skewer, add the Corn', time: 2924 },
      { step: 'In another skewer, add the Cocktail Sausages', time: 2962 },
      { step: 'In another skewer, add the Pineapple', time: 3018 },
      { step: 'In another skewer, add the Cheese', time: 3108 },
      { step: 'In another skewer, add the Shrimps', time: 3147 },
      { step: 'Grill the skewers', time: 3459 },
    ],
    stepsJP: [],
  },
  {
    img: birthday_cake,
    title: '誕生日ケーキ (Birthday cake)',
    titleEN: 'Birthday cake',
    titleJP: '誕生日ケーキ',
    price: '????',
    videoId: 'ITC4esgZbAA',
    modalCols: 5,
    recipeCard: true,
    ingredientsEN: [
      { name: '3 Eggs' },
      { name: 'Sugar' },
      { name: 'Vanilla Extract' },
      { name: 'Flour' },
      { name: 'Butter' },
      { name: 'Baking Paper' },
      { name: 'Milk' },
      { name: '3 Mangos' },
      { name: '1 Slice of Pineapple' },
      { name: 'Honey' },
      { name: '20g Apple & Mango Puree' },
      { name: 'Whipped Cream' },
      { name: 'Gelatin' },
      { name: 'White Chocolate' },
      { name: 'Heavy Cream' },
      { name: 'Apple Mint' },
    ],
    ingredientsJP: [],
    stepsEN: [
      { step: 'Crack the Eggs in a bowl, put it in a pot with hot water, mix the Eggs and Sugar with a Whisk until they reach 30°C (86°F)', time: 876 },
      { step: 'Add 5 droplets of Vanilla Extract in the bowl and continue mixing until the mixture reaches 40°C (104°F)', time: 1149 },
      { step: 'Remove the bowl from the pot and continue mixing with a Mixer until the mixture gets a white color (approx. 8 minutes)', time: 1517 },
      { step: 'Sift the Flour into the bowl and mix with a Spatula', time: 2055 },
      { step: 'In another bowl, melt the Butter until it reaches 40°C (104°F) and mix it with the Milk using a Whisk', time: 2369 },
      { step: 'Add a small portion of the Egg mixture to the Butter mixture and mix with the Spatula', time: 2462 },
      { step: 'Add the whole Butter mixture to the Egg mixture and mix with the Spatula', time: 2510 },
      { step: 'Coat the insides of a cake pan with Butter and Baking Paper, then add the mixture', time: 2659 },
      { step: 'Preheat the oven for 5 minutes, put the pan inside and bake for 40 minutes', time: 2762 },
      { step: 'Slice the Mangos and peel their skin', time: 3552 },
      { step: 'Slice the Pineapple', time: 4401 },
      { step: 'In a bowl on top of hot water, mix the Puree, Sugar and Honey', time: 5098 },
      { step: 'Add the Gelatin to the bowl and continue mixing', time: 5293 },
      { step: 'Add the Whipped Cream to the bowl and continue mixing', time: 5398 },
      { step: 'Add the White Chocolate to the bowl and continue mixing until it melts', time: 5433 },
      { step: 'Put the bowl in the refrigerator', time: 6992 },
      { step: 'In another bowl, add Heavy Cream and whip it using a Mixer while adding Sugar to make Whipped Cream', time: 7030 },
      { step: 'Remove the cake pan from the oven, remove the Cake Base and take the Baking Paper off', time: 8005 },
      { step: 'Slice the Cake Base horizontally to make 3 slices', time: 8341 },
      { step: 'Place one of the slices on top of a cake turntable, cover it with Whipped Cream and spread using an Icing Spatula', time: 8747 },
      { step: 'Add slices of Mango and Pineapple on top, then add more Whipped Cream and spread', time: 8851 },
      { step: 'Place the 2nd slice of Cake Base on top, then add more Whipped Cream and spread', time: 8993 },
      { step: 'Add more slices of Mango and Pineapple, then add more Whipped Cream and spread', time: 9086 },
      { step: 'Place the 3rd slice of Cake Base on top, then add more Whipped Cream and spread', time: 9385 },
      { step: 'Take the bowl from the refrigerator and cover one half of the top of the Cake with the contents', time: 9859 },
      { step: 'Add more slices of Mango and Pineapple on top of the covered top of the Cake', time: 9975 },
      { step: 'Add some leaves of Apple Mint to decorate', time: 10070 },
    ],
    stepsJP: [],
  },
  {
    img: napolitan_pasta,
    title: 'ナポリタン (Napolitan pasta)',
    titleEN: 'Napolitan pasta',
    titleJP: 'ナポリタン',
    price: '????',
    videoId: 'N-jbTbpLOJU',
    modalCols: 4,
    recipeCard: true,
    ingredientsEN: [
      { name: '100g Spaghetti' },
      { name: '1 Pineapple Sausage' },
      { name: '1/2 Onion' },
      { name: '1 Green Pepper' },
      { name: '50g Mushroom' },
      { name: 'Cooking Oil' },
      { name: 'Ketchup' },
      { name: '10g Butter' },
      { name: '10g Grated Cheese' },
      { name: 'Ground Parsley' },
    ],
    ingredientsJP: [],
    stepsEN: [
      { step: 'Slice the Sausage diagonally', time: 1473 },
      { step: 'Slice the Mushroom', time: 1654 },
      { step: 'Slice the Onion', time: 1822 },
      { step: 'Slice the Green Pepper', time: 1981 },
      { step: 'Add Oil to the pan and turn on the heat', time: 2280 },
      { step: 'When the pan is hot, add the Sausage slices and fry for 4 minutes while turning', time: 2361 },
      { step: 'Add the Onion to the pan with the Sausage and stir-fry for 3 minutes', time: 2620 },
      { step: 'Add 5 spoons of Ketchup to the pan and continue stirring for 2 minutes', time: 2730 },
      { step: 'Add the Mushroom to the pan and continue stirring for 3 minutes and then turn off the heat', time: 2860 },
      { step: 'Add water to a pot and bring it to a boil', time: 3063 },
      { step: 'Add the Spaghetti to the pot and cook it for 15 minutes', time: 3480 },
      { step: 'Turn on the heat again on the pan with the Sausage, Onion and Mushroom, add the Butter and stir', time: 4433 },
      { step: 'After the Butter has melted, add the Green Pepper and continue stirring for 1 minute', time: 4551 },
      { step: 'Add the Spaghetti to the pan, add more Ketchup to taste and continue stirring for 2 minutes', time: 4633 },
      { step: 'Serve on a plate, add the Grated Cheese and a pinch of Ground Parsley on top', time: 4866 },
    ],
    stepsJP: [],
  },
  {
    img: omurice,
    title: 'オムライス (Omurice)',
    titleEN: 'Omurice',
    titleJP: 'オムライス',
    price: '????',
    videoId: 'KNYf-ym6W-k',
    modalCols: 4,
    recipeCard: true,
    ingredientsEN: [
      { name: 'Ketchup' },
      { name: 'Demi Glace Sauce' },
      { name: '1 Onion' },
      { name: 'Bacon' },
      { name: 'Instant Rice' },
      { name: '3 Eggs' },
      { name: 'Dried Parsley' },
      { name: 'Cooking Oil' },
    ],
    ingredientsJP: [],
    stepsEN: [
      { step: 'In a hot pan, drop the Demi Glace Sauce and add the ketchup.', time: 1958 },
      { step: 'On the side, chop the onion and the bacon.', time: 3038 },
      { step: 'Remove the sauce from the heat and set aside', time: 3410 },
      { step: 'In a new pan add the Bacon and let it cook for a few minutes then add the onion', time: 3541 },
      { step: 'Let it cook for 5 minutes, add the Demi Glace Sauce and mix until both the bacon and onion are coated ', time: 4022 },
      { step: 'Add the rice and mix until the rice is coated with the sauce.', time: 4124 },
      { step: 'Add ketchup and mix everything.', time: 4315 },
      { step: 'Grab a few scoops of the rice mix and place it on the plate in an oval shape', time: 4807 },
      { step: 'Crack all the eggs in a bowl and whisk is until there is no more egg white visible', time: 5654 },
      { step: 'Add the oil in a new pan and wait for it to become hot. Then add the egg mix', time: 10222 },
      { step: 'Mix the eggs in the pan while shaking the pan', time: 10455 },
      { step: 'Take a spatula and fold the omelette in half', time: 10500 },
      { step: 'Carefully flip the omelette so the opening is facing down to allow it to seal', time: 10524 },
      { step: 'Lay down the omelette on top of the rice', time: 10558 },
      { step: 'Use a bottle of ketchup and write on your omelette', time: 11654 },
      { step: 'make an incision in the middle of the omelette and add the Demi Glace sauce + the Dried Parsley on top', time: 12234 },
    ],
    stepsJP: [],
  },
  {
    img: katsu_curry,
    title: 'カツカレー (Katsukare)',
    titleEN: 'Katsukare',
    titleJP: 'カツカレー',
    price: '????',
    videoId: 'Z6wMEJf8wJk',
    modalCols: 5,
    recipeCard: true,
    ingredientsEN: [
      { name: 'Beef' },
      { name: 'Pork' },
      { name: '1 Onion' },
      { name: '1 Carrot' },
      { name: '2 Potatoes' },
      { name: 'Curry Sauce Mix' },
      { name: 'Cooking Oil' },
      { name: 'Salt' },
      { name: 'Pepper' },
      { name: '1 Egg' },
      { name: 'Flour' },
      { name: 'Bread Crumbs' },
      { name: 'Instant Rice' },
      { name: 'Sliced Cabbage' },
      { name: '2 Cherry Tomatoes' },
    ],
    ingredientsJP: [],
    stepsEN: [
      { step: 'Peel and slice the Carrot', time: 1096 },
      { step: 'Peel and slice the Potatoes', time: 1304 },
      { step: 'Slice the Onion', time: 1574 },
      { step: 'Slice the Beef', time: 1815 },
      { step: 'Add the sliced ingredients in a pot with two spoons of Cooking Oil and stir-fry for 6 minutes', time: 1968 },
      { step: 'Add water to the pot until the ingredients are covered and cook for 15 minutes with the lid on', time: 2339 },
      { step: 'Tenderize the Pork', time: 2756 },
      { step: 'Season the Pork with Salt and Pepper', time: 3329 },
      { step: 'Crack the Egg in a bowl, add Flour and water, and mix with a Whisk', time: 3484 },
      { step: 'Submerge the Pork in the bowl and cover it with the mix', time: 3778 },
      { step: 'Cover the Pork with the Bread Crumbs', time: 3806 },
      { step: 'Turn off the heat for the pot, add the Curry Sauce Mix and mix', time: 3975 },
      { step: 'Add Oil in a pan, enough to deep-fry one side of the Pork and wait until it is hot', time: 4260 },
      { step: 'Place the Pork in the Oil and fry for 10 minutes while turning', time: 4553 },
      { step: 'Place the Rice, the Cabbage and the Tomatoes on a plate', time: 5582 },
      { step: 'Slice the Fried Pork and place it on top of the Cabbage', time: 5777 },
      { step: 'Serve the Curry on the plate', time: 6275 },
    ],
    stepsJP: [],
  },
  {
    img: spam_musubi,
    title: 'スパムむすび (Spam musubi)',
    titleEN: 'Spam musubi',
    titleJP: 'スパムむすび',
    price: '????',
    videoId: 'ovxzXRLozvM',
    modalCols: 5,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: bento,
    title: 'お弁当 (Bento)',
    titleEN: 'Bento',
    titleJP: 'お弁当',
    price: '????',
    videoId: 'Om-HYEPV1O4',
    modalCols: 5,
    recipeCard: true,
    ingredientsEN: [
      { name: '250g Chicken Breast' },
      { name: '50g Mashed Garlic' },
      { name: 'Soy Sauce' },
      { name: 'Mirin' },
      { name: 'Ajinomoto' },
      { name: 'Pepper' },
      { name: 'Sake' },
      { name: 'Ground Nutmeg' },
      { name: '4 Cocktail Sausages' },
      { name: '2 Smiley Potatoes' },
      { name: 'Cooking Oil' },
      { name: 'Rice' },
      { name: 'Blue Food Color' },
      { name: '1/2 Lettuce' },
      { name: '4 Broccoli Heads' },
      { name: '2 Cherry Tomatoes' },
      { name: '1 Slice of Processed Cheese' },
      { name: 'Seaweed' },
      { name: '1 Crab Stick' },
      { name: 'Flour' },
      { name: '1/4 Lemon' },
      { name: '2 Bento Boxes' },
    ],
    ingredientsJP: [],
    stepsEN: [
      { step: 'Cut the chicken breast in small pieces', time: 926 },
      { step: 'Add three spoons of Soy Sauce, the mashed garlic, two spoons of Mirin, one spoon of Ajinomoto, Pepper to taste, one spoon of Sake and one spoon of Ground Nutmeg to the Chicken', time: 1220 },
      { step: 'Knead the Chicken and mix the added ingredients', time: 1500 },
      { step: 'Let the Chicken rest for 30 minutes', time: 1600 },
      { step: 'Cut one end of 2 Cocktail Sausages to form an X shape', time: 1747 },
      { step: 'Stripe cut the sides of the other 2 Cocktail Sausages', time: 1788 },
      { step: 'Add Oil to the pan and fry the Cocktail Sausages for 1 minute and the Smiley Potatoes for 5 minutes', time: 1950 },
      { step: 'Add Blue Food Color to the Rice and mix', time: 2409 },
      { step: 'Form the Rice into a slime shape', time: 2680 },
      { step: 'Place Lettuce on the bottom of one Bento Box until it is covered', time: 2890 },
      { step: 'Place the Rice, 3 Broccoli Heads, the Smiley Potatoes, the Cherry Tomatoes and the Cocktail Sausages on top of the Lettuce', time: 3010 },
      { step: 'Take 2 circle shapes from the Slice of Processed Cheese and place them on top of the Rice like eyes', time: 3275 },
      { step: 'Take 2 small circle shapes of Seaweed and place them on top of the cheese like pupils', time: 3343 },
      { step: 'Cut the Crab Stick to make a mouth shape and place it on top of the rice', time: 3383 },
      { step: 'Add enough Oil to the pan to fry the chicken and warm it on low heat for 3 minutes', time: 3773 },
      { step: 'Take the pieces of Chicken, cover them in Flour and fry them in the pan for 10 minutes while turning', time: 3963 },
      { step: 'Place Lettuce on the bottom of the other Bento Box until it is covered', time: 5030 },
      { step: 'Place the Fried Chicken, one Broccoli Head and the Lemon on top of the Lettuce', time: 5047 },
    ],
    stepsJP: [],
  },
  {
    img: hamburger,
    title: 'ハンバーガー (Hamburger)',
    titleEN: 'Hamburger',
    titleJP: 'ハンバーガー',
    price: '????',
    videoId: '4MdM34zBqI8',
    modalCols: 4,
    recipeCard: true,
    ingredientsEN: [
      { name: '1 Hamburger Bun' },
      { name: '200g Ground Beef' },
      { name: 'Butter' },
      { name: '1 Slice of Cheese' },
      { name: '1/4 Onion' },
      { name: '1/4 Tomato' },
      { name: '1/4 Lettuce' },
      { name: 'Pepper' },
      { name: 'Soy Sauce' },
      { name: 'Mustard' },
      { name: 'Mayonnaise' },
      { name: 'Ketchup' },
      { name: 'Chili Sauce' },
      { name: '2 Pickled Cucumbers' },
      { name: 'Sugar' },
      { name: 'Cooking Oil' },
      { name: '1 Pineapple Slice' },
      { name: '3 Slices of Bacon' },
    ],
    ingredientsJP: [
      { name: 'ハンバーガーバンズ1つ' },
      { name: '牛ひき肉200g' },
      { name: 'バター' },
      { name: 'チーズ1枚' },
      { name: '玉ねぎ1/4個' },
      { name: 'トマト1/4個' },
      { name: 'レタス1/4個' },
      { name: '胡椒' },
      { name: '醤油' },
      { name: 'マスタード' },
      { name: 'マヨネーズ' },
      { name: 'ケチャップ' },
      { name: 'チリソース' },
      { name: 'ピクルス2本' },
      { name: '砂糖' },
      { name: '食用油' },
      { name: 'スライスしたパイナップル1個' },
      { name: 'ベーコン3切れ' },
    ],
    stepsEN: [
      { step: 'Slice the bun in half', time: 590 },
      { step: 'Slice the tomato', time: 727 },
      { step: 'Slice the onion', time: 820 },
      { step: 'Add one spoon of Soy Sauce to the Ground Beef', time: 1060 },
      { step: 'Add one spoon of Sugar to the Ground Beef', time: 1148 },
      { step: 'Knead the Ground Beef and mix the added ingredients', time: 1187 },
      { step: 'Add Pepper to taste to the Ground Beef', time: 1232 },
      { step: 'Knead the Ground Beef and mix the Pepper', time: 1290 },
      { step: 'Shape the Ground Beef into a patty', time: 1320 },
      { step: 'Warm the pan', time: 1682 },
      { step: 'Add Butter to the sliced bun and grill on the pan for 30 seconds on medium heat', time: 1782 },
      { step: 'Add Oil to the pan and fry the sliced onion and pineapple for 3 minutes on medium heat', time: 1890 },
      { step: 'Add Oil to the pan and fry the patty for about 3 minutes on each side until properly cooked.', time: 2181 },
      { step: 'Add Oil to the pan and fry the sliced bacon for 2 minutes', time: 2298 },
      { step: 'Cover the patty with the slice of cheese while still on the pan and wait for the cheese to melt', time: 2668 },
      { step: 'Slice the pickled cucumbers', time: 2882 },
      { step: 'Add mustard to one half of the bun', time: 2956 },
      { step: 'Add the lettuce on top of the bun', time: 3057 },
      { step: 'Add the onion on top of the lettuce', time: 3120 },
      { step: 'Add the patty on top of the onion', time: 3169 },
      { step: 'Add mayonnaise, ketchup and chili sauce to taste on top of the patty', time: 3253 },
      { step: 'Add the tomato on top of the sauces', time: 3398 },
      { step: 'Add the pineapple on top of the tomato', time: 3430 },
      { step: 'Add bacon on top of the pineapple', time: 3500 },
      { step: 'Add the pickled cucumbers on top of the bacon', time: 3553 },
      { step: 'Add the other half of the bun on top', time: 3800 },
    ],
    stepsJP: [
      { step: 'バンズを半分に切ります', time: 590 },
      { step: 'トマトをスライスします', time: 727 },
      { step: '玉ねぎをスライスします', time: 820 },
      { step: 'ひき肉にしょうゆをスプーン1杯加えます', time: 1060 },
      { step: 'ひき肉に砂糖をスプーン1杯加えます', time: 1148 },
      { step: 'ひき肉を練り混ぜます', time: 1187 },
      { step: 'ひき肉に胡椒を加えます', time: 1232 },
      { step: 'ひき肉を練り、胡椒を混ぜます', time: 1290 },
      { step: 'ひき肉をパテ状にします', time: 1320 },
      { step: '鍋を温めます', time: 1682 },
      { step: 'スライスしたバンズにバターを加え、中火で約30秒ほどフライパンで焼きます', time: 1782 },
      { step: 'フライパンに油をひき、スライスした玉ねぎとパイナップルを中火で約3分ほど炒めます', time: 1890 },
      { step: 'フライパンにオイルを入れ、中火で片面約3分ずつ中にきちんと火が通るまでパティを焼きます', time: 2181 },
      { step: 'フライパンに油をひき、スライスしたベーコンを約2分ほど炒めます', time: 2298 },
      { step: 'パティにスライスチーズをのせて、チーズが溶けるのを待ちます', time: 2668 },
      { step: 'ピクルスをスライスします', time: 2882 },
      { step: 'バンズの半分にマスタードをつけます', time: 2956 },
      { step: 'レタスを乗せます', time: 3057 },
      { step: 'レタスの上に玉ねぎをのせます', time: 3120 },
      { step: 'タマネギの上にパティをのせるます', time: 3169 },
      { step: 'パティの上にマヨネーズ、ケチャップ、チリソースをお好みで加えます', time: 3253 },
      { step: 'トマトをソースの上に加えます', time: 3398 },
      { step: 'トマトの上にパイナップルを乗せます', time: 3430 },
      { step: 'パイナップルの上にベーコンを乗せます', time: 3500 },
      { step: 'ベーコンの上にピクルスを乗せます', time: 3553 },
      { step: 'バンズの残り半分を上に乗せます', time: 3800 },
    ],
  },
  {
    img: waffle,
    title: 'ワッフル (Waffle)',
    titleEN: 'Waffle',
    titleJP: 'ワッフル',
    price: '????',
    videoId: 'OJd-gsbU7HY',
    modalCols: 5,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: takoyaki,
    title: 'たこ焼き (Takoyaki)',
    titleEN: 'Takoyaki',
    titleJP: 'たこ焼き',
    price: '????',
    videoId: 'hj3KM5_1hT4',
    modalCols: 5,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
  {
    img: butter_cookie,
    title: 'バタークッキー (Butter cookie)',
    titleEN: 'Butter cookie',
    titleJP: 'バタークッキー',
    price: '????',
    videoId: 'zL5N3gr-nfY',
    modalCols: 5,
    recipeCard: false,
    ingredientsEN: [],
    ingredientsJP: [],
    stepsEN: [],
    stepsJP: [],
  },
];

export default itemData;